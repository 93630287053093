import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../../navigation/routes";
import { motion, AnimatePresence, useScroll } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "../../../../utils/redux/store";
import { RiArrowDropDownFill } from "react-icons/ri";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";

const NavbarItems = ({ isToggled }: { isToggled: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user);
  const [showDisciplinesPagesList,setShowDisciplinesPagesList] = useState(false);

    const items = [
      { label: "Home", link: () => navigate(Routes.DefaultPage) },
      { label: "About Us", link: () => navigate(Routes.aboutUs) },
      { label: "Blogs", link: () => navigate(Routes.blogs) },
      { label: "Academies", link: () => navigate(Routes.academies) },
      { label: "Disciplines ➡", link: () => setShowDisciplinesPagesList(true) },
      { label: "Scholarship", link: () => navigate(Routes.scholarship) },
      { label: "Contact Us", link: () => navigate(Routes.contactUs) },
      { label: "Login", link: () => navigate(Routes.Login) },
      { label: "Sign Up", link: () => navigate(Routes.SignUp) },
    ];

    const disciplineLists = [
      { label: "Cricket", link: () => navigate(Routes.cricketDiscipline) },
      { label: "Dance", link: () => navigate(Routes.danceDiscipline) },
      { label: "Badminton", link: () => navigate(Routes.badmintonDiscipline) },
      { label: "Arts", link: () => navigate(Routes.artsDiscipline) },
      { label: "Karate", link: () => navigate(Routes.karateDiscipline) },
      { label: "Guitar", link: () => navigate(Routes.guitarDiscipline) },
      { label: "Mixed Martial Arts", link: () => navigate(Routes.mmaDiscipline) },
      { label: "Painting", link: () => navigate(Routes.paintingDiscipline) },
      { label: "Piano", link: () => navigate(Routes.pianoDiscipline) },
      { label: "Taekwondo", link: () => navigate(Routes.taekwondoDiscipline) },
    ]
  
    const navList = {
      visible: {
        opacity: 1,
        transition: {
          delayChildren: 0.2,
          staggerChildren: 0.07
        }
      },
      hidden: {
        opacity: 0,
        transition: {
          staggerChildren: 0.05,
          staggerDirection: -1
        }
      }
    };
  
    const navItem = {
      visible: {
        y: 0,
        opacity: 1,
        transition: {
          y: { stiffness: 1000, velocity: -100 }
        }
      },
      hidden: {
        y: 50,
        opacity: 0,
        transition: {
          y: { stiffness: 1000, velocity: -100 }
        }
      }
    };
  
    return (
      <>
        <motion.ul
          className="w-full p-4 flex flex-col gap-2 overflow-y-auto"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={navList}
        >
          {showDisciplinesPagesList ? (
            <>
              <motion.li 
                className="text-[34px] font-semibold cursor-pointer text-center" 
                variants={navItem} 
                key='back-btn' 
                onClick={() => setShowDisciplinesPagesList(false)}
              >
                <p>⬅ Back</p>
              </motion.li>
              <hr />
              {disciplineLists.map(item => {
                return (
                  <motion.li 
                    className="text-[34px] font-semibold cursor-pointer text-center" 
                    variants={navItem} 
                    key={item.label} 
                    onClick={item.link}
                  >
                    <p>{item.label}</p>
                  </motion.li>
                )
              })}
            </>
          ) : (
            items.map(item => {
              return (
                <motion.li 
                  className="text-[34px] font-semibold cursor-pointer text-center" 
                  variants={navItem} 
                  key={item.label} 
                  onClick={item.link}
                >
                  <p>{item.label}</p>
                </motion.li>
              )
            })
          )}
        </motion.ul>
      </>
    );
};

const Navbar = () => {
  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = useState(false);

  const isNative = Capacitor.isNativePlatform();

  useEffect(() => {
      if(isNative) {
          StatusBar.setOverlaysWebView({ overlay: false });
          StatusBar.setBackgroundColor({ color: '#FFFFFF' });
          StatusBar.setStyle({ style: Style.Light });
      }
  },[isNative])


  const handleToggleClick = () => {
      setIsNavOpen(prev => !prev);
  }

  const navContainer = {
      visible: {
        opacity: 1,
        transition: {
          x: { velocity: 100 },
          duration: 0.3
        }
      },
      hidden: {
        opacity: 0,
        transition: {
          x: { velocity: 100 },
          duration: 0.3
        }
      }
  };

  const disableScroll = () => {
      document.body.style.overflow = 'hidden'; // Disable scrolling
  };

  const enableScroll = () => {
      document.body.style.overflow = 'auto'; // Enable scrolling
  };

  useEffect(() => {
      if(isNavOpen){
          disableScroll(); 
      }

      return () => enableScroll();
  }, [isNavOpen]);

  const { scrollY } = useScroll();
  const [isNavMinimized, setIsNavMinimized] = React.useState(false);

  useEffect(() => {
      const handleScroll = () => {
          if (scrollY.get() > 50) {
              setIsNavMinimized(true);
          } else {
              setIsNavMinimized(false);
          }
      };
      handleScroll();
      scrollY.onChange(handleScroll);
  }, [scrollY]);

  return (
      <>
        <motion.div 
            className={`grid grid-cols-3 md:flex justify-between items-center z-[99] rounded-xl transition-all duration-600 
                ${(isNavMinimized && !isNavOpen) ? 'scale-90 scale-x-[97%] md:scale-90 shadow-md fixed top-0 left-0 right-0 p-2 md:p-4 md:px-10 opacity-60 hover:opacity-100 bg-transparent backdrop-blur-3xl hover:bg-white' : 'bg-white scale-100 p-2 md:p-6 md:px-10 relative'}
            `}
        >
            <div
                className="sm:hidden flex flex-col gap-1.5 px-2"
                onClick={handleToggleClick}
            >
                <div className=" w-8 z-100 border-b-2 border-black "></div>
                <div className=" w-8 z-100 border-b-2 border-black "></div>
                <div className=" w-8 z-100 border-b-2 border-black "></div>
            </div> 
            <div className='md:w-[160px] flex items-center justify-center md:justify-start'>
                <img
                    src='/assets/images/logo-light.webp'
                    alt='logo'
                    className='h-12 md:h-14 cursor-pointer'
                />
            </div>
            <div className='flex-grow items-center gap-14 font-semibold text-[16px] justify-center hidden md:flex'>
                <div onClick={() => navigate('/')} className='cursor-pointer nav-item-button'>Home</div>
                <div onClick={() => navigate(Routes.aboutUs)} className='cursor-pointer nav-item-button'>About Us</div>
                <div className='scale-110 cursor-pointer nav-item-button flex items-center relative text-shadow-sm group'>
                    Disciplines
                    <RiArrowDropDownFill size={32}/>
                    <div className='absolute top-full left-0 hidden group-hover:flex z-[99999]'>
                        <div className='shadow-2xl shadow-black bg-white w-max overflow-hidden gap-4 gap-x-8 p-4 px-8 rounded grid grid-cols-3 mt-2'>
                            <div onClick={() => navigate(Routes.cricketDiscipline)} className='cursor-pointer nav-item-button'>Cricket</div>
                            <div onClick={() => navigate(Routes.danceDiscipline)} className='cursor-pointer nav-item-button'>Dance</div>
                            <div onClick={() => navigate(Routes.artsDiscipline)} className='cursor-pointer nav-item-button'>Arts</div>
                            <div onClick={() => navigate(Routes.karateDiscipline)} className='cursor-pointer nav-item-button'>Karate</div>
                            <div onClick={() => navigate(Routes.badmintonDiscipline)} className='cursor-pointer nav-item-button'>Badminton</div>
                            <div onClick={() => navigate(Routes.paintingDiscipline)} className='cursor-pointer nav-item-button'>Painting</div>
                            <div onClick={() => navigate(Routes.mmaDiscipline)} className='cursor-pointer nav-item-button'>MMA</div>
                            <div onClick={() => navigate(Routes.taekwondoDiscipline)} className='cursor-pointer nav-item-button'>Taekwondo</div>
                            <div onClick={() => navigate(Routes.guitarDiscipline)} className='cursor-pointer nav-item-button'>Guitar</div>
                            <div onClick={() => navigate(Routes.pianoDiscipline)} className='cursor-pointer nav-item-button'>Piano</div>
                        </div>
                    </div>
                </div>
                <div onClick={() => navigate(Routes.academies)} className='scale-110 cursor-pointer nav-item-button text-shadow-sm'>Academies</div>
                <div onClick={() => navigate(Routes.blogs)} className='cursor-pointer nav-item-button'>Blogs</div>
                <div className='cursor-pointer nav-item-button flex items-center relative group'>
                    More
                    <RiArrowDropDownFill size={32}/>
                    <div className='absolute top-full left-0 hidden group-hover:flex z-[99999]'>
                        <div className='shadow-2xl bg-white w-auto overflow-hidden mt-2 flex flex-col gap-4 p-4 px-8 rounded'>
                            <div onClick={() => navigate(Routes.scholarship)} className='cursor-pointer nav-item-button'>Scholarships</div>
                            <div onClick={() => navigate(Routes.contactUs)} className='cursor-pointer nav-item-button'>Contact Us</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='md:w-[160px] flex justify-end'>
                {user ? (
                    <div 
                        className='font-semibold p-1 text-white bg-blue-400 flex rounded-full items-center gap-2 cursor-pointer'
                        onClick={() => navigate(Routes.SelectStudentProfile)}
                    >
                        <img 
                            src='/assets/images/user.png'
                            className='h-8 w-8 md:h-10 md:w-10'
                        />
                        {/* {user?.user?.username} */}
                    </div>
                ): (
                    <button className='py-2 px-4 md:py-3 md:px-8 text-sm md:text-lg rounded-full bg-[#4285f4] font-semibold text-white active:scale-95 transition-all'>
                        Sign in
                    </button>
                )}
            </div>
        </motion.div>
        <AnimatePresence>
            {isNavOpen && (
                <motion.div
                    className="w-full h-screen fixed bg-white top-0 left-0 z-20 flex flex-col items-center justify-center md:hidden"
                    initial="hidden"
                    animate={isNavOpen ? "visible" : "hidden"}
                    exit="hidden"
                    variants={navContainer}
                >
                    <NavbarItems isToggled={isNavOpen} />
                </motion.div>
            )}
        </AnimatePresence>
      </>
  );
}

export default Navbar


